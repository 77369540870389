<template>
	<div class="whiteBack">
    <h4 v-if="showtitle"class="mb-3">Trade & Technical Skills</h4>
    
    <div class="text-left" v-if="userProfile && userProfile.tech && userProfile.tech.length >= 1">
      <label>If you are professional with of the skills below, please also upload any certifications or licenses on your uploads page:</label>
      <div class="flex mb-3 mt-3 align-start" v-for="(item, index) in tech" :key="item.id">
        <div class="inline" style="width:2.4rem;">
          <input class="mb-0 mt-0" type="checkbox" :id="index" :value="item" v-model="userProfile.tech" @change="updateProfile()">
        </div>
        <div class="flex flex-column" style="width:calc(100% - 2.4rem);">
          <span class="caption" :for="index">{{item.title}}</span>
          <!-- <p class="caption">
            {{job.description}}
          </p> -->
        </div>
      </div>
      <button class="btn btn__primary mt-3" v-if="userProfile.tech >=1" @click="updateProfile()">Submit</button>
    </div>

    <div class="text-left" v-if="userProfile && (!userProfile.tech || userProfile.tech.length == 0)">
      <label>If you are professional with of the skills below, please also upload any certifications or licenses on your uploads page:</label>
      <div class="flex mb-3 mt-3 align-start" v-for="(item, index) in tech" :key="item.id">
        <div class="inline" style="width:2.4rem;">
          <input class="mb-0 mt-0" type="checkbox" :id="index" :value="item" v-model="skills">
        </div>
        <div class="flex flex-column" style="width:calc(100% - 2.4rem);">
          <span class="caption" :for="index">{{item.title}}</span>
          <!-- <p class="caption">
            {{job.description}}
          </p> -->
        </div>
      </div>
      <button class="btn btn__primary mt-3" v-if="skills && skills.length >=1" @click="updateSkills()">Submit</button>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['userProfile', 'showtitle'],
  data: () => ({
    skills: [],
    tech: [
      { title: 'Painter'},
      { title: 'Electrician'},
      { title: 'Forklift Driver'},
      { title: 'Floor Tech'},
      { title: 'Scissor Lift Operator'},
    ]
  }),
  // computed: {
  //   ...mapState(['jobs']),
  // },
  created () {
    if (!this.jobs || this.jobs.length < 1) {
      this.$store.dispatch("getJobsState")
    }
  },
  methods: {
    updateProfile(){
      let userProfile = this.userProfile
      this.$store.dispatch('updateUser', userProfile)
    },
    updateSkills(){
      let userProfile = this.userProfile
      userProfile.tech = this.skills
      this.$store.dispatch('updateUser', userProfile)
    },
  },
  beforeDestroy () {
    this.$store.dispatch("clearJobsState")
  }
}
</script>