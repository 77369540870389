<template>
	<div class="whiteBack" :class="{ invalid: (!userProfile.address || !userProfile.address.state) }">
    <label class="mb-2">Home Address</label>
    <div class="caption">You will need to enter your address before accessing the payroll module.</div>
    <form ref="form">
      <div class="mb-3 mt-3">

        <div v-if="userProfile.address && Object.keys(userProfile.address).length >= 1">
          <div class="flex justify-space-between align-start" v-if="!showAddressChange">
            <div>
              <label v-if="userProfile.firstName && userProfile.firstName">{{userProfile.firstName}} {{userProfile.lastName}}</label>
              <div class="caption">{{userProfile.address.street_number}} {{userProfile.address.street}} <span v-if="userProfile.address.unit">#{{userProfile.address.unit}}</span><br />{{userProfile.address.city}}, {{userProfile.address.state}} {{userProfile.address.zip}}</div>
              <div class="mt-3">
                <label for="unit">Unit Number: (optional)</label>
                <input type="text" v-model.trim="userProfile.address.unit" @change="updateProfile()" />
                <!-- <button class="btn btn__primary mt-3" v-if="place">Submit</button> -->
              </div>
              
              
            </div>
            <button class="btn btn__outlined btn__small ml-3" @click="showAddChange()" v-if="!showAddressChange">Edit</button>
            <button class="btn btn__outlined btn__small ml-3" @click="cancelAddChange()" v-if="showAddressChange">cancel</button>
          </div>

          <div v-if="showAddressChange">
            <div class="mb-2 mt-3 caption">Change your address below:</div>
            <label>
              <gmap-autocomplete
                class="v-input v-input__control outline-fix"
                 placeholder="Street Address" @place_changed="setPlace">
              </gmap-autocomplete>
            </label>

            <!-- @place_changed="setPlace" -->

            

  <!--           <label for="unit">Unit Number: (optional)</label>
              <input type="text" v-model.trim="userProfile.address.unit" />
            <button class="btn btn__primary" @click="setPlace()" v-if="!showAddressChange">Edit</button> -->
          </div>
          

        </div>

        <div v-if="!userProfile.address || Object.keys(userProfile.address).length === 0">
        
      
          <label>
            <gmap-autocomplete
              class="v-input v-input__control outline-fix"
              @place_changed="setPlace" placeholder="Enter New Address">
            </gmap-autocomplete>
          </label>
        </div>
      </div>
    </form>
	</div>
</template>

<script>
import firebase from 'firebase/app';

export default {
  props: ['userProfile', 'h2'],
  data: () => ({
    valid: true,
    center: { lat: 45.508, lng: -73.587 },
    marker: {},
    address: {},
    place: '',
    showAddressChange: false,
  }),
  computed: {
    complete () {
      if 
      (this.userProfile.address && Object.keys(this.userProfile.address).length >= 1)
      {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    updateProfile(){
      let userProfile = this.userProfile
      this.$store.dispatch('updateUser', userProfile)
      console.log('updating address')
      const updateHomeAddress = firebase.functions().httpsCallable('updateHomeAddress')
      if (userProfile && userProfile.workerId) {
        updateHomeAddress({
          user: userProfile
        })
        .then(result => {
          if (result && result.data && result.data.url) {
            this.iframaData = result.data.url
            console.log(result.data)
          }
        })
      }
    },
    setPlace(place) {
      this.currentPlace = place
        if (this.currentPlace) {
          const marker = {
            lat: this.currentPlace.geometry.location.lat(),
            lng: this.currentPlace.geometry.location.lng()
          };
          this.marker = {position: marker}
          this.place = this.currentPlace
          this.userProfile.center = marker;
          this.currentPlace = null;
        }
        if (place.address_components) {
          this.performingRequest = true
          this.userProfile.address = {}
          setTimeout(() => {
              this.performingRequest = false
          }, 1000)
          console.log(place)
          console.log(this.userProfile.address)
          var components = place.address_components;
          for (var i = 0, component; component = components[i]; i++) {
          if (component.types[0] == 'street_number') {
              this.userProfile.address.street_number = component['long_name'];
          }
          if (component.types[0] == 'route') {
              this.userProfile.address.street = component['long_name'];
          }
          if (component.types[0] == 'locality' || component.types[0] == 'sublocality_level_1' || component.types[0] == 'natural_feature') {
              this.userProfile.address.city = component['long_name'];
          }
          if (component.types[0] == 'administrative_area_level_1') {
              this.userProfile.address.state = component['short_name'];
          }
          if (component.types[0] == 'country') {
              this.userProfile.address.country = component['short_name'];
          }
          if (component.types[0] == 'postal_code') {
              this.userProfile.address.zip = component['short_name'];
          }
        }
      }

      this.updateProfile()
      this.place = ''
      this.showAddressChange = false
      this.$emit('clicked', this.valid)
    },

    

    showAddChange() {
      this.showAddressChange = true
    },
    cancelAddChange() {
      this.showAddressChange = false
    },
    showChange() {
      this.showEdit = true
    },
    cancelChange() {
      this.showEdit = false
    },
    // setPlace(place) {
    //   this.currentPlace = place
    //     if (this.currentPlace) {
    //       const marker = {
    //         lat: this.currentPlace.geometry.location.lat(),
    //         lng: this.currentPlace.geometry.location.lng()
    //       };
    //       this.marker = {position: marker}
    //       this.place = this.currentPlace
    //       this.userProfile.center = marker;
    //       this.currentPlace = null;
    //     }
    //     if (place.address_components) {
    //       this.performingRequest = true
    //       this.userProfile.address = {}
    //       setTimeout(() => {
    //           this.performingRequest = false
    //       }, 1000)
    //       console.log(this.userProfile.address)
    //       var components = place.address_components;
    //       for (var i = 0, component; component = components[i]; i++) {
    //       if (component.types[0] == 'street_number') {
    //           this.userProfile.address.street_number = component['long_name'];
    //       }
    //       if (component.types[0] == 'route') {
    //           this.userProfile.address.street = component['long_name'];
    //       }
    //       if (component.types[0] == 'locality') {
    //           this.userProfile.address.city = component['long_name'];
    //       }
    //       if (component.types[0] == 'administrative_area_level_1') {
    //           this.userProfile.address.state = component['short_name'];
    //       }
    //       if (component.types[0] == 'country') {
    //           this.userProfile.address.country = component['short_name'];
    //       }
    //       if (component.types[0] == 'postal_code') {
    //           this.userProfile.address.zip = component['short_name'];
    //       }
    //     }
    //   }
    //   this.updateProfile()
    //   this.$emit('clicked', this.valid)
    // },
  },
  mounted () {
    this.$nextTick(() => {
      this.$emit('clicked', this.valid)
    })
  },
  beforeDestroy () {
  	this.$store.dispatch('clearErrors')
  }
}
</script>