<template>
  <div class="dashboard__container--body" v-if="(userProfile  && userProfile.id)">
    <div class="dashboard__container--body--col shiftCard">
      <ProfileContact :userProfile="userProfile" :lout="true" :h2="false" />
      <ProfileAddress :userProfile="userProfile" />
    </div>
    <div class="dashboard__container--body--col shiftCard">
      <ProfileEmail :userProfile="userProfile" />
      <ProfileDOB :userProfile="userProfile" />
      <ProfilePhoneVerifcationOnProfile />
    </div>
    <div class="dashboard__container--body--col shiftCard">
      <ProfileEmergency :userProfile="userProfile" :showtitle="false" />
    </div>
    <div class="dashboard__container--body--col shiftCard">
      <div class="whiteBack mb-5">
        <h3>Additional Links:</h3>
        <div class="mt-1">
          <router-link :to="{ name: 'accountPhotos' }" class="darkLink">
            <div class="listItem flex justify-space-between"><span>Profile Pictures<span class="danger" v-if="!hasPhoto"><i class="fa-solid fa-triangle-exclamation danger ml-3"></i></span><span v-else><i class="fa-solid fa-check ml-3"></i></span></span><i class="fa-regular fa-arrow-right fa-2x"></i></div>
          </router-link>
          <router-link :to="{ name: 'accountUploads' }" class="darkLink">
            <div class="listItem flex justify-space-between"><span>My Certifications<span class="danger" v-if="!hasCerts"><i class="fa-solid fa-triangle-exclamation danger ml-3"></i></span><span v-else><i class="fa-solid fa-check ml-3"></i></span></span><i class="fa-regular fa-arrow-right fa-2x"></i></div>
          </router-link>
          <router-link :to="{ name: 'accountResume' }" class="darkLink">
            <div class="listItem flex justify-space-between"><span>My Resumé<span class="danger" v-if="!hasResume"><i class="fa-solid fa-triangle-exclamation danger ml-3"></i></span><span v-else><i class="fa-solid fa-check ml-3"></i></span></span><i class="fa-regular fa-arrow-right fa-2x"></i></div>
          </router-link>
          <router-link :to="{ name: 'accountId' }" class="darkLink">
            <div class="listItem flex justify-space-between"><span>Upload My ID<span class="danger" v-if="!hasID"><i class="fa-solid fa-triangle-exclamation danger ml-3"></i></span><span v-else><i class="fa-solid fa-check ml-3"></i></span></span><i class="fa-regular fa-arrow-right fa-2x"></i></div>
          </router-link>
          <router-link :to="{ name: 'sexualHarassment' }" class="darkLink" v-if="isCA">
            <div class="listItem flex justify-space-between"><span>Sexual Harrassment Prevention Training<span class="danger" v-if="!hasHarassment"><i class="fa-solid fa-triangle-exclamation danger ml-3"></i></span><span v-else><i class="fa-solid fa-check ml-3"></i></span></span><i class="fa-regular fa-arrow-right fa-2x"></i></div>
          </router-link>
        </div>
      </div>
    </div>
    <!-- <div class="dashboard__container--body--col shiftCard">
      <ProfileTagline :userProfile="userProfile" :showtitle="false" />
    </div> -->
   <!--  <div class="dashboard__container--body--col shiftCard">
      <ProfileSkills :userProfile="userProfile" :showtitle="false" />
    </div>
    <div class="dashboard__container--body--col shiftCard">
      <ProfileTechnical :userProfile="userProfile" :showtitle="false" />
    </div> -->
    <div class="dashboard__container--body--col">
      <div class="whiteBack flex flex-row justify-space-between">
        <div>
          <label>Delete Account</label>
          <div v-if="!showDelete">
            <button class="btn btn__error btn__small mt-2 mb-2" @click="deleteAccount()">Delete Account</button>
          </div>
          <div v-if="showDelete" class="flex justify-space-between">
            <button class="btn btn__outlined btn__small mt-2 mb-2 mr-3" @click="cancelDelete()">Cancel</button>
            <button class="btn btn__error btn__small mt-2 mb-2" @click="deleteAccountForReal()">Yes, Delete Account</button>
          </div>
          <div v-if="showDelete" class="mt-2">
            <p class="caption">Warning! This cannot be undone.</p>
          </div>
        </div>
      </div>
    </div>
    



    
  </div>
</template>

<style scoped>
  .listItem {
    border-bottom: 1px solid #ddd;
    padding: 1rem 0 0.75rem 0;
  }
  .listItem .fa-2x {
    font-size: 1.8rem;
  }
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import ProfileContact from '@/components/Profile/ProfileContact.vue'
import ProfileSkills from '@/components/Profile/ProfileSkills.vue'
import ProfileTechnical from '@/components/Profile/ProfileTechnical.vue'
import ProfileEmergency from '@/components/Profile/ProfileEmergency.vue'
import ProfileTagline from '@/components/Profile/ProfileTagline.vue'
import ProfileDOB from '@/components/Profile/ProfileDOB.vue'
import ProfileEmail from '@/components/Profile/ProfileEmail.vue'
import ProfileAddress from '@/components/Profile/ProfileAddress.vue'
import ProfilePhoneVerifcationOnProfile from '@/components/Profile/ProfilePhoneVerifcationOnProfile.vue'

export default {
  name: 'accountDetails',
  computed: {
    ...mapState(['userProfile', 'currentUser']),
  },
  data: () => ({
    showDelete: false,
  }),
  components: {
    Loader,
    ProfileContact,
    ProfileAddress,
    ProfileDOB,
    ProfileEmail,
    ProfilePhoneVerifcationOnProfile,
    ProfileTagline,
    ProfileEmergency,
    ProfileSkills,
    ProfileTechnical
  },
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    isCA() {
      if (this.userProfile && this.userProfile.address && this.userProfile.address.state && this.userProfile.address.state == 'CA') {
        return true
      } else {
        return false
      }
    },
    hasID() {
      if (this.userProfile && this.userProfile.id_card_path) {
        return "approved"
      } else {
        return false
      }
    },
    hasResume() {
      if (this.userProfile && this.userProfile.picture) {
        return "approved"
      } else {
        return false
      }
    },
    hasCerts() {
      if (this.userProfile && this.userProfile.certs && this.userProfile.certs.length >= 1) {
        return "approved"
      } else {
        return false
      }
    },
    hasPhoto() {
      if (this.userProfile && this.userProfile.photoUrl) {
        return "approved"
      } else {
        return false
      }
    },
    hasHarassment() {
      if (this.userProfile && this.userProfile.harassmentCerts && this.userProfile.harassmentCerts.length >= 1 && this.userProfile.employeeId && (this.userProfile.address && this.userProfile.address.state && this.userProfile.address.state == 'CA')) {
        return "approved"
      } else {
        return false
      }
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
    deleteAccountForReal() {
      const user = this.currentUser
      console.log('deleting')

      user.delete().then(() => {

      this.$store.dispatch('logout')
      this.$router.push('/')

      }).catch((error) => {
      console.log(error)
      });
    },
    deleteAccount() {
      console.log('delete')
      this.showDelete = true
    },
    cancelDelete() {
      console.log('cancel delete')
      this.showDelete = false
      this.settingsExpand = false
      
    },
  }
}
</script>